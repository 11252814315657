import {
	ChatNodesMessageAIAnswerTypeEnum,
	ChatNodesMessageStatusEnum,
	ChatNodesMessageTypeEnum,
	ChatNodesStrategyEnum,
} from '../chat-nodes';
import { Nullable } from '../typescript';
import { ChatNodesToolEnum, ChatNodesToolUsageInterface } from '../chat-nodes';

export enum ChatBotMessageAIImpersonationEnum {
	HAIKU = 'HAIKU',
	YODA = 'YODA',
	EMINEM = 'EMINEM',
	SHAKESPEARE = 'SHAKESPEARE',
	DR_SEUSS = 'DR_SEUSS',
	PIRATE = 'PIRATE',
	GOLLUM = 'GOLLUM',
}

export interface ChatBotBaseMessageInterface {
	id: string;
	type: ChatNodesMessageTypeEnum;
	timestamp: number;
	content: string;
	chatId: string;
}

export interface ChatBotAIMessageInterface extends ChatBotBaseMessageInterface {
	type: ChatNodesMessageTypeEnum.AI;
	status: ChatNodesMessageStatusEnum;
	rawContent: string;
	rating: Nullable<number>; // 0 or 1
	toolsUsed: ChatNodesToolUsageInterface<ChatNodesToolEnum>[]; // Use generic tool usage interface
}

export interface ChatBotUserMessageInterface extends ChatBotBaseMessageInterface {
	type: ChatNodesMessageTypeEnum.USER;
	impersonation?: ChatBotMessageAIImpersonationEnum;
	answerType?: ChatNodesMessageAIAnswerTypeEnum;
	strategy: ChatNodesStrategyEnum;
	useMemory: boolean;
	response: ChatBotAIMessageInterface;
}
