import { NodeType } from '../nodes';
import { SearchNodeResponse } from '../search';
import { Nullable } from '../typescript';

export enum ChatNodesToolEnum {
	SIMILARITY_SEARCH = 'SIMILARITY_SEARCH',
	NLP_SEARCH = 'NLP_SEARCH',
}

export type ChatNodesSimilaritySearchToolInputType = {
	query: string;
	nodeIds: Nullable<Array<string>>;
};

export type ChatNodesSimilaritySearchToolOutputType = Array<{
	isUsed: boolean;
	embeddingId: string;
	text: string;
	node: SearchNodeResponse;
}>;

export type ChatNodesNLPSearchToolOutputType = {
	nodeCount: number;
	nodes: Array<{
		isUsed: boolean;
		node: SearchNodeResponse;
	}>;
};

export interface ChatNodesNLPSearchToolInputType {
	query?: string;
	nodeTypes?: Array<Exclude<NodeType, NodeType.WORKSPACE>>;
	textualPropertyFilters?: Array<{
		textualProperty: string;
		operator:
			| 'EQUALS'
			| 'NOT_EQUALS'
			| 'CONTAINS'
			| 'NOT_CONTAINS'
			| 'HAS_PROPERTY'
			| 'NOT_HAS_PROPERTY';
		value?: string;
	}>;
	datePropertyFilters?: Array<{
		dateProperty: string;
		dateRange: {
			start?: string;
			end?: string;
		};
	}>;
	numericPropertyFilters?: Array<{
		numericProperty: string;
		operator:
			| 'EQUALS'
			| 'NOT_EQUALS'
			| 'GREATER_THAN'
			| 'GREATER_THAN_OR_EQUAL'
			| 'LESS_THAN'
			| 'LESS_THAN_OR_EQUAL'
			| 'HAS_PROPERTY'
			| 'NOT_HAS_PROPERTY';
		value?: number;
	}>;
}

export type ChatNodesToolTypesMap = {
	[ChatNodesToolEnum.SIMILARITY_SEARCH]: {
		input: ChatNodesSimilaritySearchToolInputType;
		output: ChatNodesSimilaritySearchToolOutputType;
	};
	[ChatNodesToolEnum.NLP_SEARCH]: {
		input: ChatNodesNLPSearchToolInputType;
		output: ChatNodesNLPSearchToolOutputType;
	};
};

export interface ChatNodesToolUsageInterface<T extends ChatNodesToolEnum> {
	toolType: T;
	input: ChatNodesToolTypesMap[T]['input'];
	output: Nullable<ChatNodesToolTypesMap[T]['output']>;
	timestamp: number;
}
